import { MHErrorBoundary, MHTypography } from '@/components/base';
import styles from './awsTokenExpirationBanner.module.scss';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback, useEffect, useState } from 'react';
import { getParamFromLocalStorage } from "@/utils/localStoreUtils";

const TEN_HOURS     = 10 * 60 * 60 * 1000;
const TWELVE_HOURS  = 12 * 60 * 60 * 1000;
const TEN_MINUTES   = 10 * 60 * 1000;
const ONE_MINUTE    = 60 * 1000;

export const AwsTokenExpirationBanner = () => {
    const loginTime = getParamFromLocalStorage('reloadTime', Date.now());

    const [dismissedAt, setDismissedAt] = useState<number | null>(null);
    const [currentTime, setCurrentTime] = useState(Date.now());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(Date.now());
        }, ONE_MINUTE);
        return () => clearInterval(intervalId);
    }, []);

    const handleDismiss = useCallback(() => {
        setDismissedAt(Date.now());
    }, []);

    // Banner is shown if at least TEN_HOURS has passed since page refresh
    // and either the banner hasn't been dismissed or TEN_MINUTES has elapsed since dismissal.
    const showBanner =
        currentTime - loginTime >= TEN_HOURS &&
        (!dismissedAt || currentTime - dismissedAt >= TEN_MINUTES);

    const timeUntilExpiration = loginTime + TWELVE_HOURS - currentTime;
    const minutesRemaining = Math.ceil(timeUntilExpiration / ONE_MINUTE);
    const refreshText =
        minutesRemaining > 0
            ? `within ${minutesRemaining} minute${minutesRemaining === 1 ? '' : 's'}`
            : 'immediately';

    const handleReload = useCallback(() => {
        window.location.reload();
    }, []);

    if (!showBanner) {
        return null;
    }

    return (
        <MHErrorBoundary>
            <div className={styles.content}>
                <div className={styles.icon}>
                    <WarningIcon />
                </div>
                <MHTypography variant="bodyM">
                    Warning: Reload page {refreshText} to avoid loss of all communication.{' '}
                    <span className={styles.reloadText} onClick={handleReload}>
                        Reload
                    </span>
                </MHTypography>
                <button type="button" onClick={handleDismiss} className={styles.dismissButton}>
                    <CloseIcon fontSize="small" />
                </button>
            </div>
        </MHErrorBoundary>
    );
};
