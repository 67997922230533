import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {PersonnelDTO, User} from '@/types/gatewayDataModels';

import {CommonUserDataState} from './stateTypes';

const initialState: CommonUserDataState = {
    phoneCallData: {
        callerPatientId: null,
        isOutboundCallActive: false,
        showAnswerButton: false,
        awsConnectAgentStatus: 'Offline',
        initialContactId: '',
        sourceAgentEmail: '',
        queueARN: [],
        needToShowCPPPanel: false,
    },
    tokenData: {},
    missionControlIds: [],
    selectedMissionControl: null,
    permissions: {},
    patientPageTabs: [],
    routes: [],
    //user data from Auth0
    user: {roles: []},
    //user data from database
    employment: {},
    features: {},

    leftNavbarOpen: false,
    loadingHandler: {loadInitialUserDataLoading: true},
};

const SLICE_NAME = 'commonUserDataReducer';
const {actions, reducer} = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        toggleLeftSidebar: (state, action: PayloadAction<boolean>) => {
            state.leftNavbarOpen = action.payload;
        },
        setUserInitialData: (
            state,
            action: PayloadAction<{
                user: User;
                employment: PersonnelDTO;
                tokenData:
                    | {
                          routes: {entity?: string; exact?: boolean; route: string; title: string; access?: [string]}[];
                          missionControlIds: number[];
                          permissions: {[key: string]: boolean};
                      }
                    | {missionControlIds: number[]};
            }>,
        ) => {
            const {user, employment, tokenData} = action.payload;

            Object.assign(state, {
                user,
                employment,
                loadingHandler: {...state.loadingHandler, loadInitialUserDataLoading: false},
                ...tokenData,
            });

            localStorage.setItem('reloadTime', JSON.stringify(Date.now()));
        },
        updateUserData: (state) => state,
        updateUserDataSuccess: (state, action: PayloadAction<PersonnelDTO>) => {
            state.employment = action.payload;
        },
        getUserInitialData: (
            state,
            _action: PayloadAction<{
                user: User;
                tokenData:
                    | {
                          routes: {entity?: string; exact?: boolean; route: string; title: string; access?: string[]}[];
                          missionControlIds: number[];
                          permissions: {[key: string]: boolean};
                      }
                    | {missionControlIds: number[]};
            }>,
        ) => {
            return state;
        },
        setSelectedMissionControl: (state, action: PayloadAction<number>) => {
            state.selectedMissionControl = action.payload;
        },
        setOutboundCallStatus: (
            state,
            action: PayloadAction<{isOutboundCallActive: boolean; showAnswerButton?: boolean}>,
        ) => {
            const {isOutboundCallActive, showAnswerButton} = action.payload;

            state.phoneCallData = {
                ...state.phoneCallData,
                isOutboundCallActive,
                showAnswerButton:
                    showAnswerButton || showAnswerButton === false
                        ? showAnswerButton
                        : state.phoneCallData.showAnswerButton,
            };
        },
        setAwsConnectAgentStatus: (state, action: PayloadAction<string>) => {
            state.phoneCallData = {...state.phoneCallData, awsConnectAgentStatus: action.payload};
        },
        setConnectingPhoneStatuses: (
            state,
            action: PayloadAction<{initialContactId: string; isIncomingCall: boolean}>,
        ) => {
            const {initialContactId, isIncomingCall} = action.payload;

            state.phoneCallData = {
                ...state.phoneCallData,
                initialContactId,
                isIncomingCall,
            };
        },
        setQueueARN: (
            state,
            action: PayloadAction<
                {
                    endpointARN?: string;
                    endpointId?: string;
                    type: string;
                    name: string;
                    phoneNumber: string;
                    agentLogin: string;
                    queue?: string;
                }[]
            >,
        ) => {
            state.phoneCallData = {...state.phoneCallData, queueARN: action.payload};
        },
        setConnectingPhoneInboundStatuses: (
            state,
            action: PayloadAction<{
                isOutboundCallActive: boolean;
                showAnswerButton: boolean;
                initialContactId: string;
                sourceAgentEmail: string;
                isIncomingCall: boolean;
                callerPatientId?: string | number;
            }>,
        ) => {
            const {
                isOutboundCallActive,
                showAnswerButton,
                initialContactId,
                sourceAgentEmail,
                isIncomingCall,
                callerPatientId,
            } = action.payload;

            state.phoneCallData = {
                ...state.phoneCallData,
                isOutboundCallActive,
                showAnswerButton,
                initialContactId,
                sourceAgentEmail,
                isIncomingCall,
                callerPatientId,
            };
        },
        acceptContactSuccess: (state, action: PayloadAction<boolean>) => {
            state.phoneCallData = {
                ...state.phoneCallData,
                showAnswerButton: action.payload,
            };
        },
        disconnectContactSuccess: (
            state,
            action: PayloadAction<{needToShowCPPPanel: boolean; callerPatientId: string | number}>,
        ) => {
            const {needToShowCPPPanel, callerPatientId} = action.payload;

            state.phoneCallData = {
                ...state.phoneCallData,
                needToShowCPPPanel,
                callerPatientId,
            };
        },
        setTransferCallStatus: (state, action: PayloadAction<boolean>) => {
            state.phoneCallData = {
                ...state.phoneCallData,
                needToShowCPPPanel: action.payload,
            };
        },
        refreshUserFeatures: (state) => state,
        refreshUserFeaturesSuccess: (state, action: PayloadAction<{[key: string]: boolean}>) => {
            state.features = action.payload;
        },
    },
});

export const {
    setUserInitialData,
    getUserInitialData,
    setSelectedMissionControl,
    setOutboundCallStatus,
    setAwsConnectAgentStatus,
    setConnectingPhoneStatuses,
    setQueueARN,
    setConnectingPhoneInboundStatuses,
    acceptContactSuccess,
    disconnectContactSuccess,
    setTransferCallStatus,
    updateUserData,
    updateUserDataSuccess,
    refreshUserFeatures,
    refreshUserFeaturesSuccess,
    toggleLeftSidebar,
} = actions;

export default reducer;
