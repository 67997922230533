import {StoreStateShape} from '@/redux/store/storeStateTypes';

const navigationSelector = ({
    commonUserDataReducer: {
        routes,
        user,
        employment,
        loadingHandler: {loadInitialUserDataLoading},
    },
}: StoreStateShape) => ({
    routes,
    email: user?.email,
    nurseId: employment?.id,
    loading: loadInitialUserDataLoading,
});

const isVideoCallsEnabledSelector = ({
    commonUserDataReducer: {
        features: {CapabilityVideoCalls},
    },
}: StoreStateShape) => ({
    isVideoCallsEnabled: CapabilityVideoCalls,
});

const isClinicalDocumentsEnabledSelector = ({
    commonUserDataReducer: {
        features: {ShowClinicalDocumentsTab},
    },
}: StoreStateShape) => ({
    isClinicalDocumentsEnabled: ShowClinicalDocumentsTab,
});

export {isClinicalDocumentsEnabledSelector, isVideoCallsEnabledSelector, navigationSelector};
